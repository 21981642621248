import React, { useLayoutEffect, useRef } from 'react'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


// import { animated, useSpring } from "react-spring";
import vert from "./shaders/default.vert";


import { BlimpMaterial } from './materials/blimpMaterial'

import glb from './gble.glb'
import Cabin from './Cabin'

// https://blog.mozvr.com/customizing-vertex-shaders/
export default function Blimp(props) {
  const group = useRef()
  const { nodes } = useLoader(GLTFLoader, glb)
  const mat = useRef()
  const matShader = useRef()

  useLayoutEffect(() => {
    // if (!mat.current) return

    mat.current.onBeforeCompile = (shader) => {
      shader.uniforms.time = { value: 0 }
      shader.vertexShader = `uniform float time;
      ${shader.vertexShader}`
      const token = '#include <begin_vertex>'
      const customTransform = `
      vec3 transformed = vec3(position);
      transformed.x = position.x 
      + sin(position.y*2.0 + time*1.5)*0.05;
      `
      shader.vertexShader = shader.vertexShader.replace(token,customTransform)
      matShader.current = shader
    }

  }, [])

  useFrame(state => {
    if (!matShader.current) return
    matShader.current.uniforms.time.value += 0.025;

    const time = state.clock.getElapsedTime()
    group.current.rotation.x = Math.sin(time / 4) / 6 // nose pitch
    group.current.rotation.y = -Math.sin(time / 3) / 1.5
  })

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
    >
      {/* <mesh
        material={nodes.Cone001.material}
        geometry={nodes.Cone001.geometry}
        position={[1.36, 2.17, -1.56]}
        rotation={[0, 0.06, -0.53]}
        scale={[0.62, 0.62, 0.19]}
      />
      <mesh
        geometry={nodes.Cone001.geometry}
        position={[1.36, 2.17, -1.56]}
        rotation={[0, 0.06, -0.53]}
        scale={[0.62, 0.62, 0.19]}
      >
        <meshPhongMaterial
          attach="material"
          color="#DF946E"
        />
      </mesh> */}
      
      <mesh
        scale={[1.15, 1.15, 1.75]}
        position={[0, 0, 0]}
        vertexShader={vert}
        // receiveShadow
        castShadow
      >

        <sphereBufferGeometry
          attach="geometry" args={[ 2.15, 32, 32/*, 0, Math.PI * 2, 0, Math.PI / 3,*/ ]}
        />
        <BlimpMaterial color={'#F5B99E'} attach="material" ref={mat} />
      </mesh>


      <Cabin />
      
      <mesh
        geometry={nodes.Cube001.geometry}
        position={[0, 0, 3.55]}
        scale={[0.02, -1.39, 0.64]}
        receiveShadow
        castShadow
      >
        <meshPhongMaterial
          attach="material"
          // vertical fin
          color="#B5625B"
        />
      </mesh>
      <mesh
        geometry={nodes.Cube002.geometry}
        position={[0, 0, 3.56]}
        rotation={[0, 0, 1.56]}
        scale={[0.02, -1.39, 0.64]}
        receiveShadow
        castShadow
      >
        <meshPhongMaterial
          attach="material"
          // hoz fin
          color="#CD8473"
        />
      </mesh>
      {/* <mesh
        geometry={nodes.Sphere001.geometry}
        position={[-0.04, 0, -2.97]}
        scale={[1.2, 1, 1]}
      />
      <mesh
        geometry={nodes.Sphere002.geometry}
        position={[-0.01, 0.32, -3.78]}
        scale={[0.34, 0.22, 0.22]}
      >
        <meshPhongMaterial
          attach="material"
          color="#DF946E"
        />
      </mesh> */}
    </group>
  )
}
