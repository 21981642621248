import React, { useRef, useCallback, useLayoutEffect } from "react";
import { a, useSpring, interpolate} from "react-spring";
import useEventListener from '@use-it/event-listener'

import { useWindowSize } from "@react-hook/window-size";
import useScrollCallback from "../../hooks/useScrollCallback";

// import TreesBg from './trees-bg.inline.svg'
import treesBg from './trees-bg.svg'
// import TreesFg from './trees-fg.inline.svg'
import treesFg from './trees-fg.svg'
import Mountains from './mountains.inline.svg'
import Moon from './moon.inline.svg'

import style from './Landscape.module.scss'

function mapRange(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

const AnimatedMountains = a(Mountains)
const AnimatedMoon = a(Moon)


export default function Landscape() {
  const [winWidth, winHeight] = useWindowSize()

  const docHeight = useRef()

  const [{ st, xy }, set] = useSpring(() => ({ 
    st: 0,
    from: {
      st: 200
    },
    xy: [0, 0],
    config: {
      mass: 1,
      tension: 150,
      friction: 60
    }
  }))

  useLayoutEffect(() => {
    // const cappedHeight = winHeight > 760 ? 760 : winHeight
    docHeight.current = document.body.scrollHeight - winHeight

    // set({ st: 0 })
  }, [winWidth, winHeight, set])

  const interpMoon = st.interpolate((o) => {
    const y = mapRange(o, 0, docHeight.current, -350, 0)
    return `translate3d(0px, ${y}px, 0px)`
  })
  const interpMountains = interpolate([st, xy], (o, xy) => {
    const y = mapRange(o, 0, docHeight.current, -150, 0)
    return `translate3d(${xy[0] * 0.02 }px, ${y + (xy[1] * 0.04)}px, 0px)`
  })
  const interpTreesBg = interpolate([st, xy], (o, xy) => {
    const y = mapRange(o, 0, docHeight.current, -60, 0)
    return `translate3d(${xy[0] * 0.010}px, ${y + (xy[1] * 0.015)}px, 0px)`
  })
  const interpTreesFg = interpolate([st, xy], (o, xy) => {
    const y = mapRange(o, 0, docHeight.current, -30, 0)
    return `translate3d(${xy[0] * 0.008 }px, ${y}px, 0px)`
  })

  const onMove = useCallback(({ clientX: x, clientY: y }) => {
    set({ xy: [x - winWidth / 2, y - winHeight / 2] })
  }, [winWidth, winHeight, set])


  useScrollCallback((st) => set({ st }));
  useEventListener('mousemove', onMove)


  return (

      <div className={style.outer}>

        <AnimatedMoon
          className={style.moon}
          style={{
            transform: interpMoon
          }}
        />
        <AnimatedMountains
          className={style.mountains}
          style={{
            transform: interpMountains
          }}
        />

        {/* <div style={{overflow: 'hidden'}}> */}
        <a.div
          className={style.treesBg}
          style={{
            transform: interpTreesBg,
            backgroundImage: `url(${treesBg})`
          }}
        />
        <a.div
          className={style.treesFg}
          style={{
            transform: interpTreesFg,
            backgroundImage: `url(${treesFg})`
          }}
        />
        {/* </div> */}

        
      </div>

    
  );
}
