import React, { Suspense, useRef } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { OrbitControls } from "drei"


import Blimp from './BlimpModel'
import style from './Blimp.module.scss'


const Lighting = () => {
  const light = useRef()

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime()
    light.current.position.x = Math.sin(time * 0.5) * 300
    light.current.position.y = Math.cos(time * 0.5) * 400
    light.current.position.z = Math.cos(time * 0.5) * 300
  })

  return (
    <>
      <ambientLight color="#efefef" intensity={0.85} />
      {/* <pointLight
        position={[50, 150, -100]}
        color="#F6BA9F"
        intensity={0.15}
      /> */}
      <mesh ref={light}>
        {/* <sphereBufferGeometry args={[4, 8, 8]} attach="geometry" /> */}
        {/* <meshBasicMaterial color="#fff" attach="material" /> */}
        <pointLight color="#F6BA9F" intensity={0.2} />
      </mesh>
    </>
  )
}
function Scene() {


  return (
    <div className={style.outer}>

      <Canvas
        shadowMap
        // gl2
        // gl={{ 
        //   // powerPreference: "high-performance",
        //   alpha: true,
        //   antialias: true,
        //   stencil: false,
        // }}
        // colorManagement
        // camera={{ position: [45, 4, 4], far: 50 }}
        orthographic
        camera={{
          near: -40,
          far: 400,
          position: [90, 0, 0],
          zoom: 25,
        }}
        // resize={{
        //   scroll: false, debounce: { scroll: 50, resize: 100 }
        // }}
        concurrent
        className={style.canvas}
        style={{
          height: 220,
          width: 220,
        }}
      >

        {/* <Camera /> */}

        <Suspense fallback={null}>
          <Lighting />
        </Suspense>

        <Suspense fallback={null}>
          <Blimp />
        </Suspense>
        <OrbitControls
          enableDamping
          // dampingFactor={0.1}
          enableZoom={false}
          // maxZoom={100}
          // minZoom={40}
          enableRotate
          enablePan={false}
          // minPolarAngle={Math.PI / 4}
          // maxPolarAngle={Math.PI / 3}

        />
      </Canvas>
    </div>

  );
}

export default Scene;
