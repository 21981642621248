// https://codepen.io/nicksheffield/pen/ZYXWLW?editors=1010
import React, { useEffect, useRef } from "react"
import { useSpring } from "react-spring";
import useEventListener from '@use-it/event-listener'
import useScrollCallback from "../../hooks/useScrollCallback";

import style from './Stars.module.scss'


function random(min, max, float) {
  return float
    ? Math.random() * (max - min) + min
    : Math.floor(Math.random() * (max - min + 1)) + min
}

const DPR = 2

const Stars = () => {

  const canvasRef = useRef()
  const ctxRef = useRef(null)

  const particles = useRef([])

  // Canvas set up, runs only once
  useEffect(() => {
    ctxRef.current = canvasRef.current.getContext('2d')
    // ctxRef.current.fillStyle = 'orange'
    canvasRef.current.width = window.innerWidth * DPR
    canvasRef.current.height = window.innerHeight * 1

    for (var i = 0; i < 200; i++) {
      particles.current.push({
        x: random(-300, canvasRef.current.width + 300),
        y: random(-300, canvasRef.current.height + 300),
        s: random(1, 5),
      })
    }
    
  }, [])

  const [, set] = useSpring(() => ({
    st: 0,
    from: {
      st: -500,
    },
    pageX: 0,
    pageY: 0,
    onFrame: ({ st, pageX, pageY }) => {

      // heres the money on animating canvas
      if (!canvasRef.current) return null
      if (!ctxRef.current) return null

      ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      for (var i = 0; i < particles.current.length; i++) {
        var p = particles.current[i];

        var x = p.x + ((((canvasRef.current.width / 2)) + (pageX * 0.1) * p.s) * 0.1);
        var y = p.y + ((((canvasRef.current.height / 2) + (pageY * 0.1) - (st * 0.8) ) * p.s) * 0.1);

        ctxRef.current.fillStyle = '#fff';
        ctxRef.current.beginPath();
        const alpha = (Math.cos(y / 20) + 1) / 1.5
        // console.log(alpha)
        
        ctxRef.current.globalAlpha = alpha
        ctxRef.current.arc(x, y, p.s , 0, Math.PI * 2, true)
        // ctxRef.current.opacity = 0.5
        ctxRef.current.fill();
        ctxRef.current.closePath();
      }
    },
    config: {
      mass: 10,
      tension: 220,
      friction: 200
    }
  }));

  function handleMouseMove({ pageX, pageY }) {
    set({ pageX, pageY })
  }

  useScrollCallback((st) => set({ st }));
  useEventListener('mousemove', handleMouseMove)

  // resize handler // TODO: debounce
  useEffect(() => {
    const handleResize = function () {
      // need to re-measure
      canvasRef.current.width = window.innerWidth * DPR
      canvasRef.current.height = window.innerHeight * 1
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])


  return (

    <canvas
      ref={canvasRef}
      className={style.canvas}
    />

  )
}

export default Stars
