import React, { useRef, useLayoutEffect } from "react";
import { a, useSpring } from "react-spring";

import { useMedia } from "react-use";
import { useWindowHeight } from "@react-hook/window-size";
import useScrollCallback from "../../hooks/useScrollCallback";

import style from './Heading.module.scss'

function mapRange(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

export default function Text({ text }) {
  const winHeight = useWindowHeight();
  const screenMd = useMedia("(min-width: 576px)");

  const ref = useRef()

  const t = useRef(0);
  useLayoutEffect(() => {
    t.current = ref.current.getBoundingClientRect().top + window.pageYOffset;
  }, []);

  const [{ st }, set] = useSpring(() => ({
    st: 0,
    config: {
      mass: 1,
      tension: 300,
      friction: 90
    }
  }));

  useScrollCallback((st) => set({ st }));

  return (
    <div ref={ref} className={style.textOuter}>
      <a.h2
        className={style.text}
        style={{
          transform: st.interpolate((o) => {
            if (!screenMd || typeof document === undefined) return `translate3d(0, 0, 0)`; // no motion for small screens

            const range = 80;
            const docHeight = document.body.scrollHeight + winHeight;
            const from = 0 + t.current; //t.current - (winHeight ) //- (range * 0.5)
            const to = docHeight + t.current; //t.current + (winHeight) //+ (range * 0.5)

            const y = mapRange(o, from, to, range, -range); //- (winHeight / range)

            

            return `translate3d(0, ${y - range - 25}px, 0)`;
          }),
        }}
      >
        {text}
      </a.h2>
    </div>
  );
}
