import React, { useMemo } from 'react'
import * as THREE from 'three'

export const blimpTexture = (width = 512, height = 512) => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')

  // bg
  ctx.fillStyle = '#B5625B'
  ctx.fillRect(0, 0, width, height)


  // bg
  ctx.fillStyle = '#F6BAA0'
  ctx.fillRect(0, height * 0.5, width, height)


  const bulge = 50

  ctx.fillStyle = '#BD6955'
  ctx.beginPath()
  ctx.moveTo(0, height * 0.5)
  ctx.bezierCurveTo(0, bulge, width, bulge, width, height * 0.5)
  ctx.fill()

  ctx.fillStyle = '#E0926C'
  ctx.beginPath()
  ctx.moveTo(0, height * 0.5)
  ctx.bezierCurveTo(0, height - bulge, width, height - bulge, width, height * 0.5)
  ctx.fill()

  // document.body.prepend(canvas)

  return canvas
}


export const BlimpMaterial = React.forwardRef(({ color, attach }, ref) => {
  const texture = useMemo(() => blimpTexture(1024, 512), [])

  return (
    <meshPhongMaterial
      ref={ref}
      attach={attach}
    // color={color}
    // normalScale-x={0.15}
    // normalScale-y={0.15}
    >
      <canvasTexture
        // attach="normalMap"
        attach="map"
        // format={THREE.RGBFormat}
        // mapping={THREE.CubeUVReflectionMapping}
        args={[texture]}
        wrapS={THREE.RepeatWrapping}
        wrapT={THREE.RepeatWrapping}
        // flipY={false}
        repeat-x={2}
        repeat-y={1}
        rotation={Math.PI}
        offset={[0.5, 1.0]}
        center={[1.0, 0.5]}
        anisotropy={16}
      />
    </meshPhongMaterial>
  )
})
