import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import Logo from "../components/Logo/Logo"
import Section from "../components/Section/Section"
import Badges from "../components/Badges/Badges"
import Stars from "../components/Stars/Stars"
import Blimp from '../components/Blimp/Blimp'
import Landscape from '../components/Landscape/Landscape'

import SEO from "../components/seo"

type DataProps = {
  // site: {
  //   buildTime: string
  // }
}

const IndexPage: React.FC<PageProps<DataProps>> = () => (
  <Layout>
    <SEO title="Guerilla Creative AR" />

    <Stars />

    <Logo />

    <Section headingText="A modern form of magic">
      
      <div className="content">
        <blockquote className="blockquote">
          “Any sufficiently advanced technology is indistinguishable from magic.”
          <cite className="cite">Arthur C. Clarke</cite>
        </blockquote>
        <p>Augmented Reality is a perception changer. A storyteller. An entertainer. It’s a magical portal that lets you peek behind the curtain of reality.  GC AR is your ticket into this new world of limitless possibilities. Brought to you by the fine folks at Guerilla Creative.</p>

      </div>
    </Section>
    
    <Section headingText="Get the app">
      <Badges />
    </Section>

    <Section dark headingText="How to begin your AR experience">

      <div className="content">
        <p>
          Once GC AR is installed on your phone or tablet, continue to follow the instructions that directed you to this website.
        </p>
        <p>
          <small>GC AR is a custom Augmented Reality experience.  If you weren’t specifically directed to this site, you can still view our demo by visiting the HOW TO section of the GC AR app.</small>
        </p>
      </div>
    </Section>
    
    <Blimp />

    <Landscape />

  </Layout>
)

export default IndexPage
