import React from "react"

import style from './Badges.module.scss'

import apple from './apple.svg'
import google from './google.svg'

const Badges = () => (
  <section className={style.outer}>

    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=tv.guerillacreative.gcar" className={style.anchor}>
      <img className={style.img} src={google} alt="Get it on Google Play"/>
    </a>
    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/au/app/gc-ar-guerilla-creative/id1469854112" className={style.anchor}>
      <img className={style.img} src={apple} alt="Download on the App Store"/>
    </a>
    
  </section>
)

export default Badges
