import React from "react"
import Svg from './gcar.inline.svg'

import style from './Logo.module.scss'

const Logo = () => (
  <div className={style.outer}>
    <Svg className={style.svg} />
    <h1 className="u-visually-hidden">
      Guerilla Creative Augmented Reality
    </h1>
  </div>
)

export default Logo
