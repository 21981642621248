import React from "react"
import Heading from '../Heading/Heading'
import style from './Section.module.scss'

const Section = ({children, dark, headingText }) => (
  <section className={[style.section, dark ? style.dark : ''].join(' ')}>
    <div className={style.inner}>

      {headingText && <Heading text={headingText} />}

      {children}

    </div>

  </section>
)

export default Section
