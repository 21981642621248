import * as THREE from 'three'
import React, { useLayoutEffect, useRef } from 'react'

// https://discourse.threejs.org/t/cheap-round-edged-box-vertex-shader/8066

export default function Cabin() {

  const mat = useRef()

  useLayoutEffect(() => {
    const settings = {
      segments: 9,
      radius: { value: 0.5 },
      size: {
        value: new THREE.Vector3(
          1,
          0.8,
          1,
        )
      }
    }

    mat.current.onBeforeCompile = shader => {

      shader.uniforms.boxSize = settings.size;
      shader.uniforms.radius = settings.radius;


      shader.vertexShader = `
      uniform vec3 boxSize;
      uniform float radius;
      ` + shader.vertexShader;

      shader.vertexShader = shader.vertexShader.replace(
        '#include <begin_vertex>',
        `#include <begin_vertex>

        vec3 signs = sign(position);
        vec3 box = boxSize - vec3(radius);
        box = vec3(max(0.0, box.x), max(0.0, box.y), max(0.0, box.z));
        vec3 p = signs * box;

        transformed = signs * box + normalize(position) * radius;
        
        // re-compute normals for correct shadows and reflections
        objectNormal = all(equal(p, transformed)) ? normal : normalize(position); 
        transformedNormal = normalize(normalMatrix * objectNormal);
        `
      );
    };


  }, [])


  return (
    <mesh
      position={[0, -2.14, 0]} 
      receiveShadow
    >
      <boxBufferGeometry
        attach="geometry" 
        args={[1, 1, 1, 9, 9, 9]}
      />
      <meshLambertMaterial
        ref={mat}
        attach="material"
        color="#B5625B"
        // emissive="#BD6955"
        // emissiveIntensity={0.25}
      />
    </mesh>
  )
}
